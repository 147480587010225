import * as types from '../mutations-types'
export default {
    namespaced: true,
    
    state: {
        events: []
	},
    
    actions: {
        fetchAll({ commit }){
            return axios.get('/news')
            .then((response) => {
                commit(types.STORE_EVENTS, response.data.filter(c => !c.deletedAt));
                return response.data.filter(c => !c.deletedAt);
            })
        },

        fetchBySlug({ commit }, { slug }){
            return axios.get(`/news/${slug}`)
            .then((response) => {
                return response.data;
            })
        }
    },
    
    mutations: {
        [types.STORE_EVENTS](state, events){
            state.events = events;
        }
    },
    
    getters:{
        getEventById: state => id => state.events.find(c => c.id === id)
    }
}