<template>
	<div>
		<div class="node">
			<div v-for="set in sets" :key="set.id">
				<!-- set name -->
				<router-link :to="{name: 'products', query: {setId: set.id}}" class="logo">
					<img :src="set.image" alt />
					{{ set.name }}
				</router-link>

				<!-- inner categories -->
				<div
				  class="logoExpand"
				  v-for="(category, index) in categories.filter(c => c.setsIds.includes(set.id))"
				  :key="category.id"
				>
					<img src="/assets/expand.png" :ref="`expandBtn-${category.id}-${index}`"/>
					<button
					  class="collapse-btn"
					  @click="
						fetchProducts(category);
						$refs[`expandBtn-${category.id}-${index}`][0].classList.toggle('expand');
						$router.push({ name: 'products', query: { categorySlug: category.slug } })
					  "
					  type="button"
					  data-bs-toggle="collapse"
					  :data-bs-target="'#' + set.name +'-collapse-' + index"
					  aria-expanded="false"
					  :aria-controls="set.name + 'collapse'"
					>
						{{ category.name }}
					</button>
				
					<!-- inner products -->
					<div class="collapse" :id="set.name +'-collapse-' + index">
						<!-- loader -->
						<loader v-if="!category.products" small class="align-items-start mt-2"/>
						<div v-else class="space" v-for="product in category.products" :key="product">
							<router-link
							  :to="{name: 'product', params:{slug: product.slug}}"
							  class="text-decoration-none"
							>
								{{ product.name }}
							</router-link>
						</div>
						<p
						  class="ms-3 mt-1 mb-0 text-secondary fst-italic"
						  v-if="category.products ? category.products.length === 0 : false"
						>
							no products
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from './Loader.vue'
import sets from '@/helpers/sets'
import { notFound } from '@/helpers/functions'
import { mapState } from 'vuex'
export default {
	name: 'treeView',

	components:{
		Loader
	},

	data() {
		return {
			sets,
			selectedMenuIndex: null,
		};
	},

	methods:{
		notFound,
		
		fetchProducts(category){
			if (!category.products){
				this.$store.dispatch('categories/fetchBySlug', { slug: category.slug })
				.then((data) => {
					category.products = data.categoriesProduct;
				})
				.catch((e) => {
					this.notFound(e);
				})
			}
		}
	},

	computed: {
		...mapState({
			categories: state => state.categories.categories
		}),
	}
};
</script>

<style scoped>
* {
	color: var(--text-color);
}
.node {
	font-family: Montserrat;
	font-size: 14px;
	line-height: 20px;
}
.node *:hover {
	color: inherit;
}
.logo {
	display: inline-block;
	margin-top: 20px;
	text-decoration: none;
}
.logo img {
	width: 25px;
	margin-right: 7px;
}
.logoExpand {
	margin-top: 12px;
	margin-left: 30px;
}
.collapse-btn{
	background-color: transparent;
	border: none;
}
.logoExpand img {
	height: 7px;
	margin-right: 5px;
	transition: transform .3s;
}
.logoExpand img.expand {
	transform: rotate(90deg);
}
.space {
	margin-top: 10px;
	margin-left: 30px;
	list-style-type: circle;
	display: list-item;
}
.active-route{
	font-weight: bolder;
}
</style>
