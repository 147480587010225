<template>
	<div class="container px-lg-0">
		<div class="big-title">
			<div class="title-name">About us</div>
			<breadcrumbs
				:links="[
					{ name: 'Home', to: {name: 'home'} },
					{ name: 'About Us', to: {name: 'about-us'} , active: 'true' },
				]"
			></breadcrumbs>
		</div>
		<div class="contant">
			<div class="top">
				<div class="title">
					About our
					<br />company
				</div>
				<p class="mb-4">
					AL RADWAN Company 	is one of the most important leading healthcare products companies in Syria, was founded in 2010 as a medical supplier for Orthopedic, Neurosurgery, Dental, Oral and Maxillofacial implants and its instruments, in addition to a wide range of medical supplies for hospitals, clinics, and personal use products.
				</p>
				
				<div class="title">
					R & D <br> department
				</div>
				<p class="p-left">
					In Al-Radwan, we seek to distribute tasks to a professional team capable of attracting all new developments and improvements and adding it to our products thus improving quality and efficiency.
				</p>
			</div>
			<div class="right">
				<div class="t-right">Compay Profile</div>
				<div class="profile">
					<img src="/assets/vision.png" alt />
					<div>
						<div class="t-profile">Vision</div>
						<p class="p-profile">
							We are committed to improve lives through our medical technologies, services, and solutions, to limiting uncertainty for surgeons, so they can concentrate on providing the best patient care.
						</p>
					</div>
				</div>
				<div class="profile">
					<img src="/assets/mission.png" alt />
					<div>
						<div class="t-profile">Mission</div>
						<p class="p-profile">
							The Mission is our ethical framework and inspirational goal guiding our day-to-day work. It reminds us that our efforts are transforming millions of lives each year and to assist surgeons in restoring the feeling of Motion.
						</p>
					</div>
				</div>
				<div class="profile">
					<img src="/assets/values.png" alt />
					<div>
						<div class="t-profile">Our Servies</div>
						<p class="p-profile">
							We are dedicated to the delivery of Quality healthcare around Syrian Arab Republic, through a wide distributor’s network all over Syria.
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';
export default {
	components: {
		Breadcrumbs
	}
};
</script>

<style scoped>
* {
	font-size: 14px;
	color: #1b1b1b;
}
p {
	line-height: 1.3rem;
}

.big-title {
	padding: 90px 0 50px;
	color: #262626;
}
.title-name {
	font-family: MontserratBlack;
	text-transform: uppercase;
	font-size: 52px;
	line-height: 4rem;
	margin-bottom: 10px;
}
.contant {
	display: flex;
	border-top: 2px solid var(--borders-color);
	padding-top: 20px;
}
.title {
	font-size: 32px;
	font-family: MontserratBlack;
	border-left: 4px solid var(--orange-color);
	padding-left: 20px;
	margin: 20px 0;
	line-height: 2.4rem;
}
.top {
	flex: 2;
	padding-right: 40px;
}

.right {
	flex: 2;
	padding-bottom: 10px;
}
.t-right {
	color: var(--grey-color);
	font-size: 24px;
	margin: 20px 0;
	font-family: MontserratBlack;
}
.profile {
	display: flex;
	padding: 10px 0;
	font-size: 13px;
}
.t-profile {
	font-size: 14px;
	font-family: MontserratBold;
}
.profile img {
	height: 50px;
	padding-right: 10px;
}

@media only screen and (max-width: 768px) {
	* {
		font-size: 13px;
	}
	.container {
		padding: 0 20px;
	}
	.big-title {
		font-size: 23px;
		padding: 35px 0;
	}
	.title-name {
		font-size: 43px;
		line-height: 3rem;
	}
	.contant {
		display: block;
	}
	.title {
		padding: 0;
		padding-left: 15px;
		font-size: 26px;
	}
	p {
		line-height: 1.15rem;
	}
	.top {
		padding: 0;
	}
	.t-right {
		margin: 30px 0 10px;
		font-size: 22px;
	}
	.profile {
		padding: 3px 0;
	}
	.images {
		flex-wrap: wrap;
		padding-top: 10px;
	}
}
</style>
