<template>
    <div class="page-404">
        <img class="logo" src="/assets/logo.png" alt="al radwan logo">
        <div class="d-flex flex-column ms-5">
            <h1 class="title-404 d-flex flex-column">
                <span>404</span>
                <span>page not found</span>
            </h1>

            <p class="not-found-text">
                The page is not found. <br>
                We will redirect you to the Home page in seconds <span class="loading-dots">...</span>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    mounted(){
        setTimeout(() => {
            this.$router.push({ name: 'home' });
        }, 10000)
    }
}
</script>

<style scoped>
.page-404 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}
.page-404 *{
    color: var(--text-color);
}
.page-404 .logo{
    filter: brightness(0);
    max-width: 150px;
}
.title-404{
    margin-bottom: 1rem;
    font-weight: bold;
}
.title-404 > span:first-child{
    font-weight: bold;
}
.title-404 > span:last-child{
    font-size: 1.5rem;
}
.not-found-text{
    margin: 0;
    line-height: 2rem;
}
.loading-dots{
    display: inline-block;
    width: 20px;
    font-weight: bold;
    animation: loading-dots .8s ease-in-out .5s infinite alternate;
}
@keyframes loading-dots {
    from{
        letter-spacing: 0px;
    }
    to{
        letter-spacing: 5px;
    }
}
</style>