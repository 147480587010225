<template>
	<div>
		<router-link :to="to" class="contacto" :class="{ sub: theme === 'sub' }">
			{{ text }}
			<img src="/assets/Arrow.png" />
		</router-link>
	</div>
</template>

<script>
export default {
	name: 'btn',
	props: {
		text:{
			type: String
		},
		theme:{
			type: String
		},
		to:{
			type: Object,
			default: () => ({})
		}
	},
};
</script>

<style>
.contacto {
	border-radius: 30px;
	padding: 10px 25px;
	background-color: var(--orange-color);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: max-content;
	text-decoration: none;
	cursor: pointer;
	border: none;
	transition: box-shadow .15s;
}
.contacto, .contacto:link, .contacto:hover{
	color: var(--grey-color);
}
.sub {
	color: #333;
	background-color: white;
	border: #333 solid 1px;
	font-size: 13px;
	padding: 6px 20px;
}
.contacto * {
	display: inline;
}

.contacto img {
	width: 13px !important;
	margin-left: 6px;
}

.contacto:hover {
	-webkit-box-shadow: 1px 1px 3px #999;
	box-shadow: 1px 1px 3px #999;
}
</style>
