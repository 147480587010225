<template>
	<loader v-if="loading" logo height="80vh"/>
	<div v-else class="container px-lg-0">
		<vue-easy-lightbox
			scrollDisabled
			escDisabled
			moveDisabled
			:visible="visible"
			:imgs="eventImages"
			:index="index"
			@hide="handleHide"
		></vue-easy-lightbox>

		<div class="title">
			<div class="title-name">Event</div>
			<breadcrumbs
				:links="[
				  { name: 'Home', to: {name: 'home'} },
				  { name: 'Events', to: {name: 'all-events'} },
				  {
				  	name: event.title,
				  	to: {},
				  	active: 'true',
				  },
				]"
			></breadcrumbs>
		</div>
		<div class="row pt-lg-4 pt-2">
			<div class="main col-lg-8">
				<div class="event-title">
					{{ event.title }}
				</div>
				<div class="date">
					<img src="/assets/calendar.png" alt />
					{{ moment(event.date) }}
				</div>
				<div class="img text-center">
					<img :src="event.primaryImage.url" alt />
				</div>
				<div class="para" v-html="event.content"></div>
				<div class="more-img">
					<div class="mi-title">More Images</div>
					<div
						class="mi-img"
						v-for="(image, index) in eventImages.slice(0, 4)"
						:key="index"
						@click="showMultiple(index)"
					>
						<img :src="image" alt />
						<div
							class="mi-full-text"
							v-if="eventImages.length > 4 && index === 3"
						>+ {{ eventImages.length - 4 }}</div>
					</div>
					<p v-if="eventImages.length === 0" class="fw-bold text-secondary py-2 mb-0">
						NO MORE IMAGES
					</p>
				</div>
			</div>
			<div class="related col-lg-4">
				<div class="r-title">Related News</div>
				<news-card
				  v-for="event in events.filter(c => c.id !== event.id).slice(0, 4)"
				  :key="event.id"
				  :event="event"
				/>
				<p
				  v-if="events.filter(c => c.id !== event.id).length === 0"
				  class="fw-bold text-secondary py-2 mt-2 mb-0"
				>
					NO RELATED NEWS
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import NewsCard from './NewsCard.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Loader from '@/components/Loader.vue';
import { moment, notFound } from '@/helpers/functions';

import VueEasyLightbox from 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.es5.esm.min.js';
import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css';
import { mapState } from 'vuex';

export default {
	name: 'event',
	props: ['eventSlug'],
	components: {
		NewsCard,
		Breadcrumbs,
		Loader,
		VueEasyLightbox
	},
	data() {
		return {
			visible: false,
			index: 0,

			loading: true,
			event: null
		};
	},

	watch:{
		eventSlug(){
			this.fetchAll()
		}
	},

	methods: {
		show() {
			this.visible = true;
		},
		handleHide() {
			this.visible = false;
		},
		showMultiple(a) {
			this.index = a;
			this.show();
		},

		moment,
		notFound,

		fetchEvent(){
			return this.$store.dispatch('events/fetchBySlug', { slug: this.eventSlug })
			.then((data) => {
				this.event = data;
			})
			.catch((e) => {
				this.notFound(e);
			})
		},

		fetchAllEvents(){
			if (this.events.length === 0){
				return this.$store.dispatch('events/fetchAll')
				.then((data) => {
					this.event = data;
				})
				.catch((e) => {
					this.notFound(e);
				})
			}
		},

		fetchAll(){
			this.loading = true;
			Promise.all([
				this.fetchEvent(),
				this.fetchAllEvents()
			])
			.finally(() => {
				this.loading = false;
			})
		}
	},

	computed:{
		...mapState({
			events: state => state.events.events
		}),

		eventImages() {
			if (this.event) {
				return this.event.images.filter(c => c.url !== this.event.primaryImage.url).map(c => c.url);
			} else {
				return [];
			}
		}
	},

	created(){
		this.fetchAll();
	}
};
</script>

<style scoped>
.title {
	padding: 45px 0 55px;
	border-bottom: 2px solid var(--borders-color);
	color: #262626;
}
.title-name {
	font-family: MontserratBlack;
	text-transform: uppercase;
	font-size: 38px;
	line-height: 3rem;
}
.main {
}
.event-title {
	font-size: 25px;
	font-family: MontserratBoldEx;
}
.date {
	color: grey;
	font-size: 15px;
	padding-bottom: 15px;
	display: flex;
	align-items: center;
}
.date img {
	width: 18px;
	padding-right: 4px;
}
.img img {
	border-radius: 15px;
	max-width: 400px;
}
.para {
	font-size: 17px;
	color: var(--text-color);
	padding: 15px 0;
	margin: 0px;
}
.more-img {
	display: grid;
	grid-gap: 10px;
}
.mi-title {
	font-size: 20px;
	font-family: MontserratBold;
	padding-bottom: 10px;
	grid-column-start: 1;
	grid-column-end: 6;
}
.mi-img {
	border: 2px solid var(--borders-color);
	border-radius: 10px;
	position: relative;
	padding: 3px;
	max-width: 200px;
}
.mi-img img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
/* .mi-full {
  opacity: 0.3;
} */
.mi-full-text {
	position: absolute;
	width: 100;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 101%;
	height: 101%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #000a;
	border-radius: 7px;
	color: white;
	font-weight: bold;
	font-size: 20px;
	font-family: MontserratBoldEx;
	cursor: pointer;
}
.related {
}
.r-title {
	font-size: 20px;
	font-family: MontserratBoldEx;
	border-bottom: 2px solid;
	border-image: linear-gradient(
			to right,
			var(--orange-color) 41%,
			var(--borders-color) 25%
		)
		1;
	padding: 10px 0 15px;
}
@media only screen and (max-width: 992px) {
	.container {
		padding: 0 25px !important;
	}
	.title {
		padding-top: 35px;
	}
	.title-name {
		font-size: 43px;
	}
	.para {
		font-size: 14px;
	}
	.mi-title {
		grid-column-end: 3;
	}
	.mi-img {
		height: 40vw;
	}
}

/*       lightbox        */
/*       lightbox   end     */
</style>
<style>
svg.vel-icon{
	filter: invert(1);
}
</style>