<template>
	<div class="container">
		<div class="title">
			<div class="title-name">Contact Us</div>
			<breadcrumbs
				:links="[
					{ name: 'Home', to: { name: 'home' } },
					{ name: 'Contact Us', to: { name: 'contact-us' }, active: 'true' },
				]"
			></breadcrumbs>
		</div>
		<div class="main row">
			<div class="left col-lg-6 col-12">
				<form @submit.prevent="submit" ref="form" novalidate>
					<div class="f-title">Get In Touch</div>
					<input
						v-model="email"
						type="email"
						placeholder="Email"
						class="email"
						:class="{ 'not-valid': validated }"
						:disabled="loading"
						required
					/>
					<select
						v-model="type"
						class="type"
						:class="{ 'not-valid': validated }"
						:disabled="loading"
						placeholder="Email"
						required
					>
						<option value selected hidden disabled>Select type</option>
						<option value="Green">Inquiry</option>
						<option value="Red">Request</option>
						<option value="Blue">Complaint</option>
						<option value="Black">Suggestion</option>
						<option value="Red">Other</option>
					</select>
					<label for="massage">Massage</label>
					<input
						v-model="body"
						type="text"
						id="massage"
						class="massage"
						:class="{ 'not-valid': validated }"
						:disabled="loading"
						required
					/>

					<button type="submit" class="btn-send" :class="{ disabled: loading }">
						<loader v-if="loading" v-model="loading" small class="my-2" />
						<span v-else>Send</span>
					</button>

					<div class="text-danger m-0 mb-2">{{ message }}</div>
				</form>
			</div>
			<div class="right col-lg-5 col-12">
				<div class="contact">
					<div class="phone">
						<img src="/assets/phone.png" alt />
						<a href="tel:+963997624444">+963 997 624 444</a>
					</div>
					<div class="addres">
						<img src="/assets/address.png" alt />Syria - Aleppo - Al-Mohafaza
					</div>
					<div class="mail">
						<img src="/assets/email.png" alt />
						<a href="mailto:alradwan.co.sy@gmail.com"
							>alradwan.co.sy@gmail.com</a
						>
					</div>
				</div>
				<div class="social">
					<a
						href="https://www.facebook.com/alradwan.co.sy"
						target="_blank"
						class="ttooltlip text-decoration-none me-3"
					>
						<div class="d-inline-flex flex-column">
							<img src="/assets/facebook-dark.png" class="mb-2">
							<span style="font-size: 12px">Radwan Co</span>
						</div>
						<!-- <span class="ttooltlip-text">Radwan Co</span> -->
					</a>
					<a
						href="https://www.facebook.com/dentalradwan"
						target="_blank"
						class="ttooltlip text-decoration-none me-3"
					>
						<div class="d-inline-flex flex-column">
							<img src="/assets/facebook.png" class="mb-2">
							<span style="font-size: 12px">Dental</span>
						</div>
						<!-- <span class="ttooltlip-text"></span> -->
					</a>
					<a
						href="https://www.linkedin.com/company/al-radwan-company/"
						target="_blank"
					>
						<img src="/assets/linkedin.png">
					</a>
					<a href="https://www.instagram.com/alradwan_co" target="_blank">
						<img src="/assets/instagram.png">
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Loader from "@/components/Loader.vue";
export default {
	components: {
		Breadcrumbs,
		Loader,
	},

	data() {
		return {
			loading: false,
			validated: false,
			message: null,

			type: "",
			email: null,
			body: null,
		};
	},

	methods: {
		submit() {
			if (this.$refs.form.checkValidity()) {
				this.validated = false;
				this.loading = true;
				this.$store
					.dispatch("feedback/send", {
						email: this.email,
						type: this.type,
						body: this.body,
					})
					.then(() => {
						this.message = null;

						this.email = null;
						this.type = "";
						this.body = null;
					})
					.catch((e) => {
						this.message = e;
					})
					.finally(() => {
						this.loading = false;
					});
			} else {
				this.validated = true;
			}
		},
	},
};
</script>

<style scoped>
* {
	padding: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-family: Montserrat;
	color: #1b1b1b;
}

.title {
	padding: 90px 0 50px;
	color: #262626;
}
.title-name {
	font-family: MontserratBlack;
	text-transform: uppercase;
	font-size: 52px;
	line-height: 4rem;
	margin-bottom: 10px;
}
.main {
	border-top: 2px solid var(--borders-color);
	padding-top: 30px;
}
.left {
	padding: 20px;
}
form {
	width: 100%;
	box-shadow: 0px 0px 20px #ddd;
	padding: 20px 50px;
	border-radius: 10px;
}
form * {
	width: 100%;
	margin: 25px 0;
	font-size: 15px;
	color: var(--text-color);
}
.f-title {
	font-size: 18px;
	font-family: MontserratBlack;
}
.email,
.massage,
.type {
	border: none;
	border-bottom: 2px solid var(--borders-color);
	padding: 10px 0;
}
.email:focus,
.massage:focus,
.type:focus {
	outline: none;
	border-bottom: 2px solid var(--orange-color);
}
.type {
	background: white;
}
.type option {
	background: white;
	padding: 10px 0;
	color: var(--text-color);
}
label {
	margin-bottom: 0px;
}
.btn-send {
	background: var(--orange-color);
	width: max-content;
	padding: 6px 40px;
	border-radius: 30px;
	color: var(--grey-color);
	border: none;
	transition: all 0.3s;
}
.right {
	padding: 50px;
	padding-right: 0;
}
.contact {
	border-bottom: 2px solid var(--borders-color);
}
.contact div {
	padding: 25px 0;
	font-size: 15px;
	color: var(--text-color);
}
.contact .mail {
	padding-bottom: 50px !important;
}
.contact img {
	height: 25px;
	margin-right: 10px;
}
.contact a:link,
.contact a:active,
.contact a:hover {
	text-decoration: none;
	color: var(--text-color);
}
.social {
	display: flex;
	padding: 50px 0;
}
.social img {
	margin-right: 30px;
	width: 25px;
}

input,
select {
	transition: border-bottom 0.3s;
}

input[required]:invalid.not-valid,
select[required]:invalid.not-valid {
	border-bottom: 2px solid rgb(245, 172, 63);
	animation: not-valid-shake 0.5s ease;
}

select[disabled] {
	background-color: rgb(250, 250, 250);
}

button[type="submit"].disabled {
	pointer-events: none;
	background: rgb(240, 240, 240);
}

@keyframes not-valid-shake {
	0% {
		transform: translateX(0);
	}
	20% {
		transform: translateX(-5px);
	}
	40% {
		transform: translateX(5px);
	}
	60% {
		transform: translateX(-5px);
	}
	80% {
		transform: translateX(5px);
	}
	100% {
		transform: translateX(0);
	}
}

@media only screen and (max-width: 992px) {
	.container {
		padding: 0 20px;
	}
	.title {
		font-size: 23px;
		padding: 35px 0;
	}
	.title-name {
		font-size: 43px;
		line-height: 3rem;
	}
	form {
		padding: 5px 30px;
	}
	form * {
		font-size: 14px;
	}
	.btn-send {
		padding: 7px 45px;
	}
	.email,
	.massage,
	.type {
		margin: 20px 0;
	}
	.right {
		padding: 10px;
	}
	.contact div {
		font-size: 18px;
	}
	.contact img {
		height: 27px;
	}
	.social img {
		width: 30px;
		height: 30px;
	}
}
</style>
