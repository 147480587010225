import * as types from '../mutations-types'
export default {
    namespaced: true,
    
    state: {
        albums: []
	},
    
    actions: {
        fetchAll({ commit }){
            return axios.get('/albums')
            .then((response) => {
                commit(types.STORE_ALBUMS, response.data);
                return response.data;
            })
        },

        fetchById({ commit }, { id }){
            return axios.get(`/albums/${id}`)
            .then((response) => {
                return response.data;
            })
        }
    },
    
    mutations: {
        [types.STORE_ALBUMS](state, albums){
            state.albums = albums;
        }
    },
    
    getters:{
        getGalleryById: state => id => state.albums.find(c => c.id === id)
    }
}