<template>
	<div class="container">
		<div class="title">
			<div class="title-name">Events</div>
			<breadcrumbs
				:links="[
				  { name: 'Home', to: {name: 'home'} },
				  { name: 'Events', to: {name: 'all-events'}, active: 'true' },
				]"
			></breadcrumbs>
		</div>

		<loader v-if="loading" height="40vh"/>
		<div v-else class="main">
			<event-card :event="event" v-for="event in events" :key="event.id"></event-card>
			<p v-if="events.length === 0" class="fw-bold py-2 text-secondary text-center border-top border-bottom rounded mb-0" style="grid-column: 1/-1;">
				NO EVENTS
			</p>
		</div>
	</div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import EventCard from '@/components/EventCard.vue';
import Loader from '@/components/Loader.vue';
import { mapState } from 'vuex';
export default {
	components: {
		Breadcrumbs,
		EventCard,
		Loader
	},

	data() {
		return {
			loading: false
		};
	},

	methods:{
		fetchAllEvents(){
			if (this.events.length === 0){
				this.loading = true
				return this.$store.dispatch('events/fetchAll')
				.then((data) => {
					this.event = data;
				})
				.finally(() => {
					this.loading = false;
				})
			}
		},
	},

	computed:{
		...mapState({
			events: state => state.events.events
		})
	},
	
	created(){
		this.fetchAllEvents();
	}
};
</script>

<style scoped>
* {
	padding: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-family: Montserrat;
	color: #1b1b1b;
}

.title {
	padding: 90px 0 50px;
	color: #262626;
}
.title-name {
	font-family: MontserratBlack;
	text-transform: uppercase;
	font-size: 52px;
	line-height: 4rem;
	margin-bottom: 10px;
}
.main {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	border-top: 2px solid var(--borders-color);
	padding-top: 30px;
}
.event {
	width: 100%;
	padding: 0 15px;
}
@media only screen and (max-width: 992px) {
	.container {
		padding: 0 20px;
	}
	.title {
		font-size: 23px;
		padding: 35px 0;
	}
	.title-name {
		font-size: 43px;
		line-height: 3rem;
	}
	.main {
		grid-template-columns: 1fr;
	}
	.event {
		padding: 0;
		height: 400px;
	}
}
</style>
