<template>
	<div class="container">
		<div class="title">
			<div class="title-name">Gallery</div>
			<breadcrumbs
				:links="[
				   { name: 'Home', to: {name: 'home'} },
				   { name: 'Gallery', to: {name: 'gallery'}, active: 'true' },
				]"
			></breadcrumbs>
		</div>

		<loader v-if="loading" height="40vh"/>
		<div v-else class="main">
			<div class="album" v-for="(album, index) in albums" :key="index">
				<router-link :to="{name: 'album', params:{id: album.id}}">
					<div class="a-img">
						<img :src="album.image.url" alt />
					</div>
				</router-link>
				<div class="a-name">{{ album.title }}</div>
				<p class="text-center m-0">{{ album.content }}</p>
			</div>
			<p v-if="albums.length === 0" class="fw-bold py-2 text-secondary text-center border-top border-bottom rounded mb-0" style="grid-column: 1/-1;">
				NO ALBUMS
			</p>
		</div>
	</div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Loader from '@/components/Loader.vue';
import { mapState } from 'vuex';
export default {
	components: {
		Breadcrumbs,
		Loader
	},
	data() {
		return {
			loading: false
		};
	},
	computed: {
		...mapState({
			albums: state => state.albums.albums
		})
	},

	methods:{
		fetchAllAlbums(){
			if (this.albums.length === 0){
				this.loading = true;
				return this.$store.dispatch('albums/fetchAll')
				.then((data) => {
					this.event = data;
				})
				.finally(() => {
					this.loading = false;
				})
			}
		},
	},

	created(){
		this.fetchAllAlbums();
	}
};
</script>

<style scoped>
* {
	padding: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-family: Montserrat;
	color: #1b1b1b;
}

.title {
	padding: 90px 0 50px;
	color: #262626;
}
.title-name {
	font-family: MontserratBlack;
	text-transform: uppercase;
	font-size: 52px;
	line-height: 4rem;
	margin-bottom: 10px;
}
.main {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	border-top: 2px solid var(--borders-color);
	padding-top: 30px;
}
.album {
	width: 100%;
	padding: 0 15px;
}
.a-img {
	cursor: pointer;
	width: 100%;
	height: 80%;
}
.a-img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 15px;
}
.a-name {
	margin: 15px 0;
	text-align: center;
	font-family: MontserratBold;
	text-transform: capitalize;
	line-height: 25px;
	height: 25px;
	overflow: hidden;
	box-sizing: content-box;
	font-size: 16px;
}
@media only screen and (max-width: 992px) {
	.container {
		padding: 0 20px;
	}
	.title {
		font-size: 23px;
		padding: 35px 0;
	}
	.title-name {
		font-size: 43px;
		line-height: 3rem;
	}
	.main {
		grid-template-columns: 1fr 1fr;
		column-gap: 1rem;
		row-gap: 1rem;
	}
	.album {
		padding: 0;
		height: 400px;
	}
	.a-name {
		font-size: 20px;
	}
}
@media only screen and (max-width: 576px) {
	.main {
		grid-template-columns: 1fr;
		row-gap: 1.5rem;
	}
}
</style>
