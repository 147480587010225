<template>
	<div class="container p-lg-0">
		<nav class="navbar navbar-expand-lg">
			<router-link class="navbar-brand d-flex align-items-center" :to="{name: 'home'}">
				<img src="/assets/logo-2.png" class="logo" />
				<div class="d-flex flex-column ms-3">
					<span class="navbar-brand-name">Al Radwan</span>
					<span class="navbar-brand-text">Best at everything</span>
				</div>
			</router-link>
			<button
				class="navbar-toggler"
				type="button"
				data-bs-toggle="collapse"
				data-bs-target="#navbarSupportedContent"
				aria-controls="navbarSupportedContent"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<i class="fas fa-bars"></i>
			</button>

			<div class="collapse navbar-collapse" id="navbarSupportedContent">
				<ul class="navbar-nav ms-auto navo">
					<li class="nav-item">
						<router-link :to="{name: 'home'}">
							Home
							<div class="line"></div>
						</router-link>
					</li>
					<li class="nav-item">
						<router-link :to="{name: 'about-us'}">
							About Us
							<div class="line"></div>
						</router-link>
					</li>
					<li class="nav-item dropdown">
						<a
							class="nav-link dropdown-toggle"
							:class="{'disabled': loading}"
							href="#"
							id="navbarDropdown"
							role="button"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							Products
							<div class="line"></div>
						</a>
						<ul class="dropdown-menu ddmo" aria-labelledby="navbarDropdown">
							<div v-for="set in sets" :key="set.id">
								<!-- set name -->
								<router-link
								  :to="{name: 'products', query:{ setId: set.id }}"
								  class="dropdown-header text-decoration-none fw-bold p-2"
								>
									<img :src="set.image" class="set-image me-2">
									{{set.name}}
								</router-link>
								<router-link
								  v-for="category in categories.filter(c => c.setsIds.includes(set.id))"
								  :key="category.id"
								  :to="{name: 'products', query:{categorySlug: category.slug}}"
								  class="dropdown-item ps-4"
								>
									{{category.name}}
								</router-link>
							</div>
						</ul>
					</li>

					<li class="nav-item">
						<router-link :to="{name: 'all-events'}">
							Events
							<div class="line"></div>
						</router-link>
					</li>
					<li class="nav-item">
						<router-link :to="{name: 'gallery'}">
							Gallery
							<div class="line"></div>
						</router-link>
					</li>
					<li class="nav-item contact">
						<btn text="Contact" :to="{name: 'contact-us'}"></btn>
					</li>
					<li class>
						<div class="search-box" ref="searchbox">
							<input
							  @keypress.enter="search(query); open_searchbox()"
							  v-model="query"
							  type="text"
							  placeholder="Search... "
							/>
						</div>
						<img :src="
                            searchLogo
                              ? `/assets/Search.png`
                              : `/assets/Search-c.png`
                            " alt class="search-logo" @click="open_searchbox"
						/>
					</li>
				</ul>
			</div>
		</nav>
	</div>
</template>

<script>
import Button from '@/components/Button.vue';
import sets from '@/helpers/sets'
import mixin from '@/helpers/mixins'
import { mapState } from 'vuex'
export default {
	name: 'Navbar',
	components: {
		Btn: Button
	},
	mixins: [mixin],

	data() {
		return {
			searchLogo: true,

			loading: true,
			sets
		};
	},
	methods: {
		open_searchbox() {
			this.$refs.searchbox.classList.toggle('openedSearch');
			this.searchLogo = !this.searchLogo;
		}
	},

	computed:{
		...mapState({
			categories: state => state.categories.categories
		})
	},

	created(){
		this.$emit('loading-categories:active');
		this.$store.dispatch('categories/fetchAll')
		.finally(() => {
			this.loading = false;
			this.$emit('loading-categories:disable');
		})
	}
};
</script>

<style scoped>
* {
	font-size: var(--fs-header);
	color: black !important;
}
.navbar-brand-name{
	font-size: 1.1rem;
	font-weight: bolder;
}
.navbar-brand-text{
	font-size: .95rem;
}
.navo {
	align-items: center;
}
.navo > li {
	margin-left: 18px;
	position: relative;
}
.navo > li > a {
	text-decoration: none;
	padding: 8px;
	display: block;
}
.line {
	position: absolute;
	width: 100%;
	border-bottom: 3px solid var(--orange-color);
	top: 54px;
	left: 0px;
	display: none;
	-webkit-transition: 300ms;
	transition: 300ms;
}
.set-image {
	width: 20px;
}
.contact {
	transform: scaleY(0.85);
}
.container {
	padding: 0px;
}
@media only screen and (max-width: 992px) {
	.navbar-brand-text{
		font-size: .9rem;
	}
	.container {
		padding: 0 25px !important;
	}
	.nav-item {
		width: 100%;
		margin: 0 !important;
		text-align: center;
	}
	.ddmo {
		width: 100%;
		text-align: center;
	}
	.contact {
		width: min-content;
		margin: auto !important;
	}
}
@media only screen and (min-width: 992px) {
	.nav-item:hover > a {
		color: var(--grey-color) !important;
		cursor: pointer;
	}
	.navo li:hover .line {
		display: block;
	}
	.ddmo {
		position: absolute;
		padding: 10px 7px;
		top: 54px;
		left: -33px;
		border-radius: 10px;
		border: none;
		-webkit-box-shadow: 0 0 3px #d5d5d5;
		box-shadow: 0 0 3px #d5d5d5;
	}
}
.navbar {
	border-bottom: 2px solid var(--borders-color);
}

.logo {
	width: 40px;
}

.search-logo {
	max-width: 20px;
}

.navbar-toggler {
	padding: 0px;
	margin-right: 5px;
	box-shadow: 0 0 0 0 !important;
}
.navbar-toggler svg {
	width: 20px;
	height: 35px;
	transform: scaleY(0.8);
}
.navbar-brand {
	display: flex;
}
.search-box {
	width: 0px;
	height: 25px;
	display: inline-block;
	visibility: hidden;
	transition: 500ms;
}
.search-box input {
	width: 100%;
	height: 100%;
	padding: .2rem;
	border-radius: 5px;
	border: 1px solid var(--borders-color);
}
.openedSearch {
	width: 150px;
	visibility: visible;
}
/*# sourceMappingURL=style.css.map */
</style>
