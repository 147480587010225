<template>
    <div class="loader" :style="{height}">
        <img v-if="isValid(logo)" class="loader-image" src="/assets/logo.png">
        <div class="loader-dots">
            <div
              class="spinner-grow spinner-grow-small"
              :class="{'spinner-grow-smaller': isValid(small)}"
              v-for="n in 3"
              :key="n"
              role="status"
            >
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        height:{
            type: String
        },

        logo: {
            default: false,
        },

        small:{
            default: false
        }
    },

    methods:{
        isValid(prop){
            return prop || typeof (prop) === 'string';
        }
    }
}
</script>

<style>
.loader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.loader-image{
    filter: brightness(0);
    width: 90px;
    margin-bottom: 1rem;
    animation: loader-image .75s .25s infinite alternate ;
}
@keyframes loader-image {
    0%{
        opacity: .5;
    }
    100%{
        opacity: 1;
    }
}
.loader-dots{
    display: flex;
    justify-content: center;
    align-items: center;
}
.spinner-grow{
    width: 1.1rem;
    height: 1.1rem;
    margin: 0 .8rem;
    animation-direction: alternate;
}
.spinner-grow-small{
    width: .9rem;
    height: .9rem;
}
.spinner-grow-smaller{
    width: .4rem;
    height: .4rem;
}
.spinner-grow:nth-of-type(1){
    animation-delay: .15s;
}
.spinner-grow:nth-of-type(2){
    animation-delay: .25s;
}
.spinner-grow:nth-of-type(3){
    animation-delay: .35s;
}
</style>