<template>
	<div>
		<router-link :to="{name: 'product', params:{slug: product.slug}}" style="text-decoration: none">
			<div class="bordero">
				<img class="product-image" :src="product.primaryImage.url" alt />
				<p class="lg">{{ product.name }}</p>
			</div>
		</router-link>
	</div>
</template>

<script>
export default {
	name: 'productCard',
	props: ['product']
};
</script>

<style>
.bordero {
	padding: 10px 20px;
	border-radius: 15px;
	cursor: pointer;
	transition: box-shadow .2s;
}
.bordero p.lg {
	font-family: MontserratMedium;
	text-align: center;
	padding-top: 10px;
	margin: 0;
	text-transform: capitalize;
	font-size: 16px;
	overflow: hidden;
}

div .product-image {
	height: 170px;
	width: 100%;
	border-radius: 3px;
	object-fit: contain;
}

.bordero:hover {
	/* -webkit-transform: translateY(-3px);
  transform: translateY(-3px); */
	-webkit-box-shadow: 1px 3px 15px #ddd;
	box-shadow: 1px 3px 15px #ddd;
}
</style>
