<template>
	<div class="b-container">
		<div class="container pt-4 px-0">
			<div class="contact row mx-0">
				<div class="title col-lg col-sm-12 mx-sm-auto">Follow Us On</div>
				<div
					class="sm-container col-lg-2 col-xm-4 mx-lg-0 mx-auto mt-lg-0 mt-3"
				>
					<a
						href="https://www.facebook.com/alradwan.co.sy"
						target="_blank"
						class="ttooltlip color-primary"
						style="filter: invert(100);"
					>
						<i class="fab fa-facebook"></i>
						<span class="ttooltlip-text">Radwan Co</span>
					</a>
					<a
						href="https://www.facebook.com/dentalradwan"
						target="_blank"
						class="ttooltlip"
					>
						<i class="fab fa-facebook"></i>
						<span class="ttooltlip-text">Dental</span>
					</a>
					<a
						href="https://www.linkedin.com/company/al-radwan-company/"
						target="_blank"
					>
						<i class="fab fa-linkedin"></i>
					</a>
					<a href="https://www.instagram.com/alradwan_co" target="_blank">
						<i class="fab fa-instagram"></i>
					</a>
				</div>
			</div>
			<div class="row main px-lg-0 mx-0 justify-content-center">
				<div class="col-lg-4 col-12 px-4 pe-lg-3 left">
					<router-link :to="{ name: 'home' }">
						<img src="/assets/logo.png" alt />
					</router-link>
					<div class="px-lg-0 px-5">
						<p>
							<span>AL RADWAN</span>
							Company is one of the most important leading healthcare prouducts
							companies in Syria.
						</p>
					</div>
				</div>
				<div class="col-lg-1 px-0"></div>
				<div class="col-lg-2 col-12 px-0 row hf">
					<div class="title-footer col-12">Helpful Links</div>
					<ul class="col">
						<li>
							<router-link :to="{ name: 'home' }">Home</router-link>
						</li>
						<li>
							<router-link :to="{ name: 'products' }">Categories</router-link>
						</li>
						<li>
							<router-link :to="{ name: 'about-us' }">About</router-link>
						</li>
					</ul>
					<ul class="col">
						<li>
							<router-link :to="{ name: 'all-events' }">Events</router-link>
						</li>
						<li>
							<router-link :to="{ name: 'gallery' }">Gallery</router-link>
						</li>
						<li>
							<router-link :to="{ name: 'contact-us' }">Contact</router-link>
						</li>
					</ul>
				</div>
				<div class="col-lg-2 col-12 pe-lg-0 cata">
					<div class="title-footer">Category</div>
					<ul class="cata-m">
						<li v-for="set in sets" :key="set.id">
							<router-link :to="{ name: 'products', query: { setId: set.id } }">
								{{ set.name }}
							</router-link>
						</li>
					</ul>
				</div>
				<div class="col-lg-3 col-12 ps-lg-5 pe-0 com-add">
					<div class="title-footer">Company Address</div>
					<ul>
						<li>
							<i class="fas fa-phone-alt"></i>
							<a href="tel:+963997624444">+963 997 624 444</a>
						</li>
						<li>
							<i class="fas fa-map-marker-alt"></i> syria - Aleppo -
							Al-Mouhafaza
						</li>
						<li>
							<i class="fas fa-envelope"></i>
							<a href="mailto:alradwan.co.sy@gmail.com"
								>alradwan.co.sy@gmail.com</a
							>
						</li>
					</ul>
				</div>
			</div>
			<div class="companys col row py-3 mx-0">
				<div class="comp col-lg col-12">
					<img src="/assets/com-1.png"/>
					<img src="/assets/com-2.png"/>
					<img src="/assets/com-3.png"/>
					<img src="/assets/com-6.png"/>
					<img src="/assets/com-5.png"/>
					<img src="/assets/com-7.png"/>
					<img src="/assets/com-8.png"/>
				</div>
				<div class="copy-right col-lg-3 col-12 mt-lg-0 mt-4">
					<p class="c">
						CopyRight&#169;{{ new Date().getFullYear() }} All Rights Reversed
					</p>
					<a class="b" href="https:www.beetronix.com" target="_blank"
						>BEETRONIX</a
					>
				</div>
			</div>
			<!-- <v-treeview /> -->
		</div>
	</div>
</template>

<script>
import sets from "@/helpers/sets";
export default {
	name: "Footer",
	data() {
		return {
			sets,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
	font-size: 14px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-family: Montserrat;
	color: #e3e3e3;
	text-decoration: none;
}
.b-container {
	margin-top: 40px;
	position: relative;
	background-color: #1b1b1b;
	background: url("/assets/footer-background.png");
	background-size: cover;
}
.sm-container {
	display: flex;
	justify-content: center;
}

.sm-container svg {
	width: 100%;
	padding: 0 0.5rem;
	filter: invert(100%);
	height: 25px;
}
.contact {
	padding: 18px 0;
}

.contact .title {
	font-size: 16px;
	font-family: MontserratBold;
}
.main {
	border-top: 1px solid #e3e3e3;
	border-bottom: 1px solid #e3e3e3;
	padding: 30px 0;
	height: 200px;
}

.left {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.left img {
	width: 120px;
	padding-top: 15px;
	padding-right: 10px;
	/* height: -webkit-min-content;
	height: -moz-min-content;
	height: min-content; */

	filter: brightness(0) invert(1);
}

.left div {
	padding: 13px 0;
	max-width: 200px;
	overflow-y: hidden;
}

.left div p {
	line-height: 1.4rem;
	font-size: 13px;
	font-family: MontserratMedium;
}

.left div p span {
	font-family: MontserratMedium;
	color: var(--orange-color);
	font-size: 13px;
}

ul {
	list-style: none;
}
.title-footer {
	padding: 10px 0;
	font-size: 17px;
	font-family: MontserratBold;
}
.main > div > ul > li {
	padding: 4px 0;
	margin: 0;
}
ul {
	padding: 0;
}
.cata {
	padding-left: 65px !important;
}

.cata-m {
	padding: 0 !important;
}
.com-add li {
	min-width: 230px;
}
.com-add li svg {
	width: 1.1rem;
	padding-right: 5px;
	filter: invert(100%);
}

.comp img {
	width: 60px;
	margin-right: 25px;
	filter: grayscale(100%);
	transition: 200ms;
}
.comp img:nth-child(5),
.comp img:nth-child(6) {
	filter: grayscale(100%) drop-shadow(0 0 #eee);
}
.comp img:hover {
	transform: scale(1.2);
}
.copy-right {
	text-align: right;
}

.copy-right p {
	margin: 0 !important;
}

.copy-right .c {
	color: #9d9d9d;
	font-size: 11px;
}

.copy-right .b {
	font-family: MontserratBold;
	font-weight: bold;
	font-size: 12px;
}
a:hover {
	color: var(--orange-color) !important;
}
@media only screen and (max-width: 992px) {
	* {
		text-align: center;
		font-size: 18px;
	}
	.sm-container {
		width: 250px !important;
	}
	.sm-container svg {
		height: 40px;
	}
	.main {
		height: auto;
	}
	.left {
		display: block;
	}
	.left img {
		width: 160px;
		margin-bottom: 10px;
	}
	.left p,
	.left span {
		font-size: 18px !important;
	}
	.left div {
		max-width: none;
	}
	.cata {
		padding: 0 !important;
	}
	.copy-right {
		text-align: center;
	}
	.title-footer {
		font-size: 22px;
	}
}
</style>
