<template>
	<loader v-if="loading" logo height="80vh"/>
	<div v-else class="container px-lg-3 px-4">
		<vue-easy-lightbox
			scrollDisabled
			escDisabled
			moveDisabled
			:visible="visible"
			:imgs="product.images.map(c => c.url)"
			:index="index"
			@hide="handleHide"
		></vue-easy-lightbox>

		<div class="title row">
			<div class="right col-lg-4 mx-lg-0 col-12 mx-sm-auto">
				<img :src="product.primaryImage.url" alt />
			</div>
			<div class="left col-lg-6 mx-lg-0 col-12 mx-sm-auto">
				<h1 class="title-name">{{product.name}}</h1>
				<breadcrumbs
					:links="getBreadcrumbLinks()"
				></breadcrumbs>
			</div>
		</div>
		<div class="contant row">
			<div class="open-tree" @click="openTree">
				<div class="ot-arrow">
					<i class="fas fa-list-ul" style="color: white"></i>
				</div>
			</div>
			<div class="treec col-lg-4 col-12" ref="tree">
				<tree-view/>
			</div>
			<div class="info col">
				<!-- product name -->
				<div class="name">{{product.name}}</div>

				<!-- description -->
				<div class="product-description" v-html="product.description"></div>

				<!-- images -->
				<div class="name">More images</div>
				<div class="photos row py-2 px-3">
					<div
						v-for="(img, index) in product.images.map(c => c.url)"
						:key="index"
						class="col-lg-3 col-6 p-2"
					>
						<div class="photos-border">
							<img :src="img" alt @click="showMultiple(index)" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="releted">
			<div class="releted-name">
				Related products
				<div class="line"></div>
			</div>
			<hr />
			<div class="up row">
				<product-card
					v-for="product in relatedProducts.slice(0, 4)"
					:key="product.name"
					:product="product"
					class="col-lg-3 col-6 p-1"
				></product-card>

				<p v-if="relatedProducts.length === 0" class="fw-bold text-secondary px-3 py-2 m-0">
					NO PRODUCTS
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import ProductCard from '@/components/ProductCard.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Loader from '@/components/Loader';
import TreeView from '@/components/TreeView.vue';
import VueEasyLightbox from 'vue-easy-lightbox';
import sets from '@/helpers/sets'
import { notFound } from '@/helpers/functions'
import { mapGetters, mapState } from 'vuex';

export default {
	name: 'product',

	props:['productSlug'],

	data() {
		return {
			index: 0,
			visible: false,

			loading: true,
			product: null,

			relatedProducts: [],
			sets,
		};
	},

	watch:{
		'productId'(){
			this.fetchAllData();
		},
		'categories.length'() {
			this.fetchRelatedProducts()
		}
	},

	methods: {
		show() {
			this.visible = true;
		},
		handleHide() {
			this.visible = false;
		},
		showMultiple(a) {
			this.index = a;
			this.show();
		},
		openTree() {
			this.$refs.tree.classList.toggle('active-tree');
		},

		getBreadcrumbLinks() {
			const category = this.getCategoryById(this.product.categoriesIds[0]);
			return [
				{ name: 'Home', to: '/' },
				{ name: 'Products', to: { name: 'products' } },
				{ 
					name: sets.find(c => c.id === category.setsIds[0]).name, 
					to: { name: 'products', query:{ setId: category.setsIds[0] } }
				},
				{ 
					name: category.name,
					to: { name: 'products', query: { categorySlug: category.slug } }
				},
				{ name: this.product.name, to: {}, active: 'true' },
			]
		},

		notFound,

		fetchProduct(){
			return this.$store.dispatch('products/fetchBySlug', { slug: this.productSlug })
			.then((data) => {
				this.product = data;
			})
			.catch((e) => {
				this.notFound(e);
			})
		},

		fetchRelatedProducts(){
			const categories = [];
			this.product.categoriesIds.forEach(id => {
				categories.push(this.getCategoryById(id));
			})
			const promises = [];
			categories.map(c => c.slug).forEach(slug => {
				this.$store.dispatch('categories/fetchBySlug', { slug })
				.then((data) => {
					this.relatedProducts = data.categoriesProduct.filter(c => c.id !== this.product.id).slice(0, 4);
				})
				.catch((e) => {
					this.notFound(e);
				})
			})
			return Promise.all(promises)
		},

		fetchAllData(){
			this.loading = true;
			this.fetchProduct()
			.then(() => {
				if (this.categories.length) {
					this.fetchRelatedProducts()
					.finally(() => {
						this.loading = false;
					})
				}
				this.loading = false;
			})
		}
	},

	computed:{
		...mapGetters({
			getCategoryById: 'categories/getCategoryById'
		}),
		...mapState({
			categories: state => state.categories.categories
		})
	},

	components: {
		ProductCard,
		TreeView,
		Breadcrumbs,
		Loader,
		VueEasyLightbox
	},

	created(){
		this.fetchAllData();
	}
};
</script>

<style scoped>
* {
	padding: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-family: Montserrat;
	color: #1b1b1b;
}

.container .title {
	padding: 20px 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	direction: rtl;
	color: #262626;
}

.container .title .left {
	max-width: 500px;
	direction: ltr;
}

.container .title .left .title-name {
	font-family: MontserratBlack;
	text-transform: uppercase;
	font-size: 52px;
	line-height: 4rem;
	padding-top: 62px;
	margin-bottom: 10px;
}

.container .title .right {
	padding: 5px;
	display: flex;
}

.container .title .right img {
	width: 350px;
	margin: 0 auto;
}

.container .contant {
	padding-top: 16px;
	border-top: 2px solid #e9e9e9;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.open-tree {
	display: none;
	height: 24px;
}
.ot-arrow {
	width: 24px;
	height: 100%;
	background: var(--text-color);
	border-radius: 4px;
	margin-right: auto;
}
.ot-arrow svg {
	margin-top: 4px;
	margin-left: 4px;
	filter: invert(100%);
}

.container .contant .treec {
	width: 30%;
	overflow-y: auto;
	min-width: 250px;
	margin-right: 70px;
}

.container .contant .info {
	width: 60%;
	/* word-spacing: -0.2rem; */
}

.container .contant .info .name {
	padding: 15px 0;
	font-size: 20px;
	font-family: MontserratBoldEx;
}

.container .contant .info strong {
	text-transform: uppercase;
	font-family: MontserratMedium;
	font-size: 14px;
	line-height: 1.4rem;
	padding-left: 5px;
	word-spacing: 0.2rem;
}

.container .contant .info p {
	margin-top: 3px;
	line-height: 1.2rem;
	padding-left: 5px;
}

.container .contant .info ul {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	list-style: none;
	list-style-position: outside;
	margin-left: 20px;
}

.container .contant .info ul li {
	list-style: disc;
	margin: 13px 0 0 18px;
}

.container .contant .info .photos .photos-border {
	border: 1px solid #e3e3e3;
	border-radius: 15px;
	display: inline-block;
}

.container .contant .info .photos div img {
	margin: auto;
	padding: 3px;
	width: 100%;
	height: 130px;
	object-fit: contain;
}

.container .releted {
	margin-top: 50px;
}

.container .releted .releted-name {
	font-size: 20px;
	font-family: MontserratBoldEx;
	position: relative;
}

.container .releted .releted-name .line {
	position: absolute;
	top: 44px;
	left: 0;
	width: 190px;
	border: 1px solid var(--orange-color);
}

.container .releted hr {
	color: #e9e9e9;
	height: 2px;
	margin: 14px 0;
}

.container .releted .up div p {
	text-align: center;
	padding-top: 10px;
	text-transform: capitalize;
}

.container .releted .up div img {
	width: 100%;
}

.container .releted .up .releted-border:hover {
	-webkit-transform: translateY(-3px);
	transform: translateY(-3px);
	-webkit-box-shadow: 1px 3px 10px #999;
	box-shadow: 1px 3px 10px #999;
}

@media only screen and (max-width: 992px) {
	.container {
		padding: 0 35px !important;
	}
	.title-name {
		font-size: 45px !important;
	}
	.left {
		text-align: center;
	}
	.open-tree {
		display: block;
	}
	.treec {
		display: none;
		height: auto !important;
		margin-bottom: 30px;
	}
	.container .contant .active-tree {
		display: block;
		width: 100%;
	}
	.container .contant .info ul {
		margin-left: 0;
	}
	.name {
		font-size: 18px !important;
		padding: 5px 0 5px 5px !important;
	}
	.photos > div {
		padding: 2px !important;
	}
}

/**       lightbox        */
/**       lightbox      end  */
/*# sourceMappingURL=style.css.map */
</style>
<style>
.product-description{
	white-space: pre-line;
}
</style>