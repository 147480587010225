import * as types from '../mutations-types'
export default {
    namespaced: true,
    
    state: {
        categories: []
	},
    
    actions: {
        fetchAll({ commit }){
            return axios.get('/categories')
            .then((response) => {
                commit(types.STORE_CATEGORIES, response.data.filter(c => !c.deletedAt));
                return response.data.filter(c => !c.deletedAt);
            })
        },
        
        fetchBySlug({ commit }, { slug }){
            return axios.get(`/categories/${slug}`)
            .then((response) => {
                return response.data;
            })
        },
    },
    
    mutations: {
        [types.STORE_CATEGORIES](state, categories){
            state.categories = categories;
        }
    },
    
    getters:{
        getCategoryById: state => id => state.categories.find(c => c.id === id),
        getCategoryBySlug: state => slug => state.categories.find(c => c.slug === slug),
    }
}