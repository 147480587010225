<template>
	<loader v-if="loading || loadingCategories" logo height="80vh" />
	<div v-else class="container px-lg-0 px-4">
		<!-- slider -->
		<div class="links">
			<a
				href="https://www.facebook.com/alradwan.co.sy"
				target="_blank"
				class="ttooltlip"
			>
				<img src="/assets/facebook-dark.png" alt="">
				<span class="ttooltlip-text ttooltlip-text--right">Radwan Co</span>
			</a>
			<a
				href="https://www.facebook.com/dentalradwan"
				target="_blank"
				class="ttooltlip"
			>
				<img src="/assets/facebook.png" alt="">
				<span class="ttooltlip-text ttooltlip-text--right">Dental</span>
			</a>
			<a
				href="https://www.linkedin.com/company/al-radwan-company/"
				target="_blank"
			>
				<img src="/assets/linkedin.png">
			</a>
			<a href="https://www.instagram.com/alradwan_co" target="_blank">
				<img src="/assets/instagram.png">
			</a>
		</div>

		<div id="slider" class="carousel slide" data-bs-ride="carousel">
			<div class="carousel-indicators">
				<button
					v-for="index in sliders.slice(0, 5).length"
					:key="index"
					type="button"
					class="carousel-indicator"
					data-bs-target="#slider"
					:data-bs-slide-to="index - 1"
					aria-current="true"
					:aria-label="'Slide' + index"
				></button>
			</div>
			<div class="carousel-inner">
				<!-- <div
					class="carousel-item"
					v-for="product in sliderProducts.filter((e) => e.isFeatured)"
					:key="product.id"
				> -->
				<div
					class="carousel-item"
					v-for="slider in sliders.slice(0, 5)"
					:key="slider.id"
				>
					<div class="slide">
						<img :src="slider.image" :alt="slider.name" />
						<div class="left">
							<div class="slide-title">{{ slider.description }}</div>
							<!-- <div class="slide-sub-title">Dental, implant solution</div> -->
							<btn
								v-if="slider.url"
								text="Details"
								class="slide-button"
								@click="openLink(slider.url)"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="latest-products">
			<div class="title">Latest Products</div>
			<div class="chose-lp lg">
				<div
					@click="tab = set.id"
					:class="{ 'active-tab': tab === set.id }"
					v-for="set in sets"
					:key="set.id"
				>
					{{ set.name }}
				</div>
			</div>
			<div class="items-lp row">
				<template v-if="filteredProducts.length > 0">
					<product-card
						class="col-lg-3 mb-4 col-6 lg"
						v-for="product in filteredProducts"
						:key="product.id"
						:product="product"
					></product-card>
				</template>
				<p v-else class="fw-bold text-center mb-5">No products.</p>
			</div>
			<btn
				text="See All Products"
				class="c-btn"
				:to="{ name: 'products' }"
			></btn>
		</div>
		<hr />
		<div class="latest-event">
			<div class="title">Latest Event</div>
			<p class="subtitle-le">
				Al-radwan seeking to be present
			</p>
			<div class="row">
				<template v-if="events.length">
					<event-card
						class="le-card col-lg-3 col-md-6 col-12"
						v-for="event in events.slice(0, 4)"
						:key="event.title"
						:event="event"
					></event-card>
				</template>
				<p v-else class="fw-bold text-center my-5">No events.</p>
			</div>
			<btn text="See All News" class="c-btn" :to="{ name: 'all-events' }"></btn>
		</div>
		<hr />
		<div class="our-values row">
			<div class="title d-flex justify-content-center align-items-center">
				General Manager
				<a
					href="https://www.linkedin.com/in/mohamad-haeyk-588348176/"
					target="_blank"
					class="
						fw-bold
						text-decoration-none text-dark text-center
						ms-2
						mt-1
						fs-6
					"
					><i class="fab fa-linkedin"></i
				></a>
			</div>

			<p class="text-center mb-4">
				At Alradwan, we believe that sustainable health is the key to our
				future. we are dedicated to the delivery of quality healthcare around
				Syrian Arab Republic and our values are to keep on with global R & D
				around the world
			</p>

			<div class="title">Our Values</div>
			<div class="item-ov col-lg-4 col-12">
				<img src="/assets/Trustworthiness.png" />
				<div>Trustworthiness</div>
			</div>
			<div class="item-ov col-lg-4 col-6 c-ov">
				<img src="/assets/Motivation.png" />
				<div>Motivation</div>
			</div>
			<div class="item-ov col-lg-4 col-6">
				<img src="/assets/Innovation.png" />
				<div>Innovation</div>
			</div>
			<btn
				text="About ALRADWAN"
				class="c-btn mt-4"
				:to="{ name: 'about-us' }"
			></btn>
		</div>
	</div>
</template>

<script>
import ProductCard from "@/components/ProductCard.vue";
import Btn from "@/components/Button.vue";
import EventCard from "@/components/EventCard.vue";
import Loader from "@/components/Loader.vue";
import Carousel from "bootstrap/js/dist/carousel";
import sets from "@/helpers/sets";
import { mapGetters, mapState } from "vuex";

export default {
	name: "Home",

	props: ["loadingCategories"],

	components: {
		ProductCard,
		Btn,
		Loader,
		EventCard,
	},

	data() {
		return {
			sets,

			tab: 1,
			loading: false,
		};
	},

	watch: {
		loading() {
			if (!this.loading) {
				this.initializeSlider();
			}
		},
	},

	computed: {
		sliderProducts() {
			return this.products.slice(0, 4);
		},

		filteredProducts() {
			return this.products.filter((c) => {
				const productSets = [];
				c.categoriesIds.forEach(id => {
					productSets.push(...this.getCategoryById(id).setsIds)
				});
				return productSets.includes(this.tab)
			}).slice(0, 8);
		},

		...mapState({
			sliders: (state) => state.sliders.sliders,
			products: (state) => state.products.products,
			events: (state) => state.events.events,
		}),
		...mapGetters({
			getCategoryById: 'categories/getCategoryById'
		})
	},

	methods: {
		initializeSlider() {
			setTimeout(() => {
				document.querySelector(".carousel-indicator").classList.add("active");
				document.querySelector(".carousel-item").classList.add("active");

				var slider = new Carousel(document.querySelector("#slider"), {
					interval: 4000,
				});
				setTimeout(() => {
					slider.next();
				}, 4000);
			}, 100);
		},

		openLink(link) {
			window.open(link)
		}
	},

	created() {
		if (this.products.length === 0 && this.events.length === 0 && this.sliders.length === 0) {
			this.loading = true;
			Promise.all([
				this.$store.dispatch("sliders/fetchAll"),
				this.$store.dispatch("events/fetchAll"),
				this.$store.dispatch("products/fetchAll"),
			]).finally(() => {
				this.loading = false;
			});
		} else if (this.sliders.length !== 0) {
			this.initializeSlider();
		}
	},
};
</script>

<style scoped>
.container {
	position: relative;
}
.links {
	position: absolute;
	left: -70px;
	top: 40px;
	z-index: 10;
}
@media only screen and (min-width: 1400px) {
	.links {
		left: -40px;
	}
}
@media only screen and (max-width: 992px) {
	.links {
		left: 25px;
	}
	.links img {
		width: 10px !important;
		height: 10px !important;
	}
}
.links img {
	width: 25px;
	height: 25px;
	margin-bottom: 30px;
	display: block;
}
.slider {
	width: 100%;
	height: 300px;
	background-color: bisque;
}
hr {
	margin: 25px 0;
}
.title {
	text-align: center;
	font-family: MontserratBoldEx;
	font-size: 27px;
	letter-spacing: 0.03rem;
	color: #262626;
}
.c-btn {
	margin: 0 auto;
	width: min-content;
	font-size: 13px;
}
@media only screen and (max-width: 992px) {
	.title {
		font-size: 23px;
	}
}
/*   latest produc5ts parts style   */
.chose-lp {
	margin: 5px auto 40px;
	width: max-content;
}
.chose-lp div {
	margin: 0 30px;
	display: inline-block;
	padding: 10px 0;
	font-size: 16px;
	color: var(--text-color);
	font-family: MontserratMedium;
	cursor: pointer;
}
.active-tab {
	color: var(--grey-color) !important;
	border-bottom: 2px solid var(--orange-color);
}

/* rsponsev */
@media only screen and (max-width: 992px) {
	.chose-lp div {
		font-size: 13px;
	}
	.chose-lp div:first-child {
		margin-left: 0;
	}
	.chose-lp div:last-child {
		margin-right: 0;
	}
}
/*   latest products parts style end  */
/*   latest events parts style   */
/*13289*/
.ltatest-evet {
}

.subtitle-le {
	margin: 0 auto;
	text-align: center;
	max-width: 450px;
	color: var(--text-color);
	font-size: 13px;
}

/* rsponsev */
@media only screen and (max-width: 992px) {
	.subtitle-le {
		font-size: 13px;
	}
	.le-card span {
		font-size: 13px;
	}
}

/*   latest events parts style end   */
/*   our values parts style   */
.our-values {
	align-items: center;
}
.item-ov {
	margin: 10px 0 20px;
	padding: 5px 0;
}
.item-ov img {
	width: 60px;
	padding: 10px;
	display: block;
	margin: 0 auto;
}
.item-ov div {
	width: min-content;
	display: block;
	margin: 0 auto;
	font-family: MontserratBold;
	font-size: 16px;
}
.c-ov {
	border-left: 1px solid #aaa;
	border-right: 1px solid #aaa;
}

/* responsev */
@media only screen and (max-width: 992px) {
	.c-ov {
		border: none;
	}
	.item-ov {
		margin: 20px 0;
	}
	.item-ov img {
		width: 40px;
		padding: 3px;
	}
}
/*   our values parts style end   */

/*                slider          */
#slider {
	margin-bottom: 20px;
}
.carousel-inner {
	height: 350px;
}
.carousel-item {
	height: 100%;
}
.slide {
	height: 100%;
	direction: rtl;
	display: flex;
	justify-content: space-between;
}
.slide img {
	padding-right: 60px;
}
.left {
	height: 100%;
	padding: 50px 0;
	direction: ltr;
}
.slide-title {
	font-size: 60px;
	font-family: MontserratBlack;
	line-height: 4.5rem;
	color: #262626;
}
.slide-sub-title {
	font-size: 20px;
	color: var(--text-color);
}
.slide-button {
	padding: 30px 0;
}
.carousel-indicators button {
	width: 5px;
	height: 5px;
	background-color: #d2d2d2;
	border: 3px solid #d2d2d2;
	border-radius: 20px;
}
/* rsponsev */
@media only screen and (max-width: 992px) {
	#slider {
		padding: 0 10px;
	}
	.carousel-inner {
		height: auto;
	}
	.slide {
		display: block;
	}
	.slide img {
		width: 80%;
		padding: 0;
		margin: 0 auto;
		display: block;
	}
	.left {
		padding: 0;
		padding-bottom: 30px;
		text-align: center;
	}
	.slide-title {
		font-size: 45px;
		line-height: 4rem;
	}
	.slide-sub-title {
		font-size: 14px;
	}
	.slide-button {
		width: max-content;
		margin: 0 auto;
		font-size: 12px;
	}
	.carousel-indicators button {
		width: 2px;
		height: 2px;
	}
}
/*         slider    end      */

.asd {
	display: block;
	margin: 100px auto;
	width: max-content;
	position: relative;
}
.vel-img-wrapper {
	position: relative !important;
}
.prev {
	border-bottom: 10px solid #0008;
	border-right: 10px solid #0008;
	transform: rotate(-45deg);
	width: 50px;
	height: 50px;
	position: absolute;
	top: 50%;
	left: 94%;
}
.next {
	border-bottom: 10px solid #0008;
	border-right: 10px solid #0008;
	transform: rotate(135deg);
	width: 50px;
	height: 50px;
	position: absolute;
	top: 50%;
	left: 2%;
}
@media only screen and (max-width: 992px) {
	.prev {
		left: 89%;
		width: 30px;
		height: 30px;
		border-bottom: 5px solid #0008;
		border-right: 5px solid #0008;
	}
	.next {
		width: 30px;
		height: 30px;
		border-bottom: 5px solid #0008;
		border-right: 5px solid #0008;
	}
}
</style>
