<template>
	<div>
		<navbar
			@loading-categories:active="loadingCategories = true"
			@loading-categories:disable="loadingCategories = false"
			v-if="$route.name !== '404'"
		/>
		<router-view :loadingCategories="loadingCategories" />
		<a
			class="whatsapp-button"
			href="https://wa.me/+963997624444"
			target="_blank"
		>
			<i class="fab fa-whatsapp"></i>
		</a>
		<v-footer v-if="$route.name !== '404'" />
	</div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../public/assets/font-awsome-all";

export default {
	name: "App",
	components: {
		Navbar,
		"v-footer": Footer,
	},

	data() {
		return {
			loadingCategories: false,
		};
	},
};
</script>

<style>
@import "~bootstrap/dist/css/bootstrap.min.css";
@font-face {
	font-family: Montserrat;
	src: url("../public/assets/Montserrat-Regular.ttf");
}

@font-face {
	font-family: MontserratMedium;
	src: url("../public/assets/Montserrat-Medium.ttf");
}

@font-face {
	font-family: MontserratBold;
	src: url("../public/assets/Montserrat-Bold.ttf");
}

@font-face {
	font-family: MontserratBoldEx;
	src: url("../public/assets/Montserrat-ExtraBold.ttf");
}

@font-face {
	font-family: MontserratBlack;
	src: url("../public/assets/Montserrat-Black.ttf");
}
.lg {
	color: var(--text-color);
	font-size: 14px;
	line-height: 1.4;
}
* {
	font-family: Montserrat;
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	color: black;
}
:root {
	--text-color: #333;
	--orange-color: #ffff00;
	--grey-color: #656565;
	--borders-color: #e9e9e9;
	--fs-header: 13.5px;
}

.color-primary path{
	fill: var(--orange-color);
}
a {
	color: currentColor;
}

input:focus {
	outline: none;
}

.node-wrapper {
	padding: 5px;
}

.tree-node {
	padding-bottom: 5px;
}

.vel-img-title {
	background: #333d;
	padding: 20px;
	border-radius: 5px;
}

.whatsapp-button {
	position: fixed;
	right: 1.5rem;
	bottom: 1.3rem;
	z-index: 2;

	display: inline-flex;
	justify-content: center;
	align-items: center;

	height: 3.5rem;
	width: 3.5rem;

	box-shadow: 0 1px 6px #333;
	background-color: var(--orange-color);
	border-radius: 50%;

	transition: all 0.3s;
}
.whatsapp-button:hover {
	transform: scale(1.05);
}
.whatsapp-button:active {
	transform: scale(1);
}
.whatsapp-button .fa-whatsapp {
	font-size: 2rem;
}

/* bootstrap override */
.dropdown-item:hover,
.dropdown-item:active {
	border-radius: 5px;
}
.dropdown-item:active {
	background-color: var(--orange-color);
}

.ttooltlip {
	position: relative;
}
.ttooltlip-text {
	position: absolute;
	top: 120%;
	left: 0;
	opacity: 0;

	display: block;
	background: #0007;
	padding: 3px 5px !important;
	border-radius: 5px;
	width: max-content;
}
.ttooltlip-text--right {
	top: 50%;
	transform: translateX(25px);
}
.ttooltlip:hover .ttooltlip-text {
	opacity: 1;
}
</style>
