<template>
	<router-link :to="{name: 'event', params:{slug: event.slug}}" class="cont">
		<div class="img">
			<img :src="event.primaryImage.url" alt />
		</div>
		<div class="left">
			<div class="date">
				<img src="/assets/calendar.png" alt />
				{{moment(event.date)}}
			</div>
			<div class="title">
				{{ event.summary.length > 50 ? event.summary.slice(0, 50) + '...': event.summary }}
			</div>
		</div>
	</router-link>
</template>

<script>
import { moment } from '@/helpers/functions'
export default {
	name: 'NewsCard',
	props: ['event'],

	methods:{
		moment
	}
};
</script>
<style scoped>
.cont {
	margin: 20px 0 0;
	display: flex;
	align-items: center;
	height: 60px;
	text-decoration: none;
}
.img {
	width: 20%;
	height: 100%;
	display: inline-block;
	padding-right: 10px;
}
.img img {
	width: 100%;
	height: 100%;
	border-radius: 5px;
	object-fit: cover;
}
.left {
	width: 80%;
	display: inline-block;
}
.date {
	color: gray;
	font-size: 10px;
}
.date img {
	width: 14px;
	padding-bottom: 4px;
}
.title {
	line-height: 1.3rem;
}
@media only screen and (max-width: 992px) {
	.cont {
		display: block;
		height: auto;
	}
	.img {
		width: 100%;
		height: 350px;
	}
	.left {
		width: 100%;
		padding: 15px 0;
	}
	.date {
		font-size: 15px;
	}
	.date img {
		width: 14px;
	}
	.title {
		font-size: 20px;
		line-height: 1.5rem;
		line-break: anywhere;
	}
}
</style>
