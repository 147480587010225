import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios'
import moment from 'moment'
import { BASE_URL } from './constants'

window.moment = moment;

window.axios = axios.create({
    baseURL: BASE_URL,
    headers: { 'accept-language': 'en' }
})

createApp(App).use(store).use(router).mount('#app');