import Product from '../pages/products/Product.vue';
import Products from '../pages/products/Products.vue';
import Home from '../pages/Home/Home.vue';
import Event from '../pages/event/Event.vue';
import AllEvents from '../pages/event/AllEvents.vue';
import Gallery from '../pages/gallery/Gallery.vue';
import Album from '../pages/gallery/Album.vue';
import ContactUs from '../pages/ContactUs/ContactUs.vue';
import AboutUs from '../pages/aboutus/AboutUs.vue';
import Page404 from '../pages/404/Page404.vue';

export default [
	{
		path: '/',
		name: 'home',
		component: Home,
	},
	{
		path: '/product/:slug',
		name: 'product',
		component: Product,
		props: route => ({ productSlug: route.params.slug })
	},
	{
		path: '/products',
		name: 'products',
		component: Products,
		props: route => ({ 
			categorySlug: route.query.categorySlug,
			setId: parseInt(route.query.setId),
			searchQuery: route.query.search,
		})
	},
	{
		path: '/event/:slug',
		name: 'event',
		component: Event,
		props: route => ({ eventSlug: route.params.slug })
	},
	{
		path: '/gallery',
		name: 'gallery',
		component: Gallery,
	},
	{
		path: '/album/:id',
		name: 'album',
		component: Album,
		props: route => ({ albumId: parseInt(route.params.id) })
	},
	{
		path: '/contact-us',
		name: 'contact-us',
		component: ContactUs,
	},
	{
		path: '/about-us',
		name: 'about-us',
		component: AboutUs,
	},
	{
		path: '/all-events',
		name: 'all-events',
		component: AllEvents,
	},
	{
		path:'/404',
		name: '404',
		component: Page404
	},
	{ 
        path: '/:pathMatch(.*)*',
        redirect: '/404' 
    },
];