import { createStore } from 'vuex'
import categories from './modules/categories'
import events from './modules/events'
import albums from './modules/albums'
import products from './modules/products'
import sliders from './modules/sliders'
import feedback from './modules/feedback'
export default createStore({
	modules: {
		categories,
		events,
		albums,
		products,
		sliders,
		feedback,
	}
})
