export default [
    {
        id: 1,
        name: 'Dental',
        image: '/assets/dental.png'
    },
    {
        id: 2,
        name: 'Neurosurgery',
        image: '/assets/naurosergary.png'
    },
    {
        id: 3,
        name: 'Orthopedics',
        image: '/assets/orthopedics.png'
    },
    {
        id: 4,
        name: 'Other',
        image: '/assets/other.png'
    }
]