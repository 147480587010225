<template>
	<loader v-if="loading" logo height="80vh"/>
	<div v-else class="container">
		<vue-easy-lightbox
			escDisabled
			moveDisabled
			:visible="visible"
			:imgs="album.images.map(c => c.url)"
			:index="index"
			@hide="handleHide"
		></vue-easy-lightbox>

		<div class="title">
			<div class="title-name">{{album.title}}</div>
			<breadcrumbs
				:links="[
					{ name: 'Home', to: {name: 'home'} },
					{ name: 'Gallery', to: {name: 'gallery'} },
					{ name: album.title, to: {}, active: 'true' },
				]"
			></breadcrumbs>
		</div>
		<div class="main">
			<div class="photo" v-for="(image, index) in album.images.map(c => c.url)" :key="index">
				<div class="p-img" @click="showMultiple(index)">
					<img :src="image" alt />
				</div>
				<div class="p-name" v-if="image.name">{{ image.name }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Loader from '@/components/Loader.vue';
import { notFound } from '@/helpers/functions'
import VueEasyLightbox from 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.es5.esm.min.js';
import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css';

export default {
	props: ['albumId'],
	components: {
		Breadcrumbs,
		Loader,
		VueEasyLightbox
	},
	data() {
		return {
			visible: false,
			index: 0,

			loading: false,
			album: null
		};
	},
	methods: {
		show() {
			this.visible = true;
		},
		handleHide() {
			this.visible = false;
		},
		showMultiple(a) {
			this.index = a;
			this.show();
		},

		notFound,

		fetchAlbum(){
			this.loading = true;
			this.$store.dispatch('albums/fetchById', { id: this.albumId })
			.then((data) => {
				this.album = data;
			})
			.catch((e) => {
				this.notFound(e);
			})
			.finally(() => {
				this.loading = false;
			})
		}
	},

	created(){
		this.fetchAlbum();
	}
};
</script>

<style scoped>
* {
	padding: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-family: Montserrat;
	color: #1b1b1b;
}

.title {
	padding: 90px 0 50px;
	color: #262626;
}
.title-name {
	font-family: MontserratBlack;
	text-transform: uppercase;
	font-size: 52px;
	line-height: 4rem;
	margin-bottom: 10px;
}
.main {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	border-top: 2px solid var(--borders-color);
	padding-top: 30px;
}
.photo {
	width: 100%;
	padding: 0 15px 30px;
	position: relative;
}
.p-img {
	width: 100%;
	height: 230px;
}
.p-img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 15px;
	cursor: pointer;
}
.p-name {
	position: absolute;
	bottom: 30px;
	text-align: center;
	text-transform: capitalize;
	line-height: 32px;
	height: 50px;
	overflow: hidden;
	font-size: 13px;
	width: calc(100% - 30px);
	background: #0008;
	color: white;
	border-bottom-right-radius: 15px;
	border-bottom-left-radius: 15px;
	padding: 10px 0;
}
.p-img img:hover {
	box-shadow: 1px 3px 10px #bbb;
}

@media only screen and (max-width: 992px) {
	.container {
		padding: 0 20px;
	}
	.title {
		font-size: 23px;
		padding: 35px 0;
	}
	.title-name {
		font-size: 43px;
		line-height: 3rem;
	}
	.main {
		grid-template-columns: 1fr 1fr;
		column-gap: 1rem;
		row-gap: 1rem;
	}
	.photo {
		padding: 0;
	}
	.p-name {
		font-size: 20px;
		bottom: 0px;
		width: 100%;
	}
}

@media only screen and (max-width: 576px) {
	.main {
		grid-template-columns: 1fr;
		column-gap: 1rem;
		row-gap: 1.5rem;
	}
	.photo img{
		height: 100%;
	}
}

/**       lightbox        */
/**       lightbox      end  */
</style>
