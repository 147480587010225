<template>
	<loader v-if="loading || loadingCategories" logo height="80vh"/>
	<div v-else class="container px-lg-3 px-4">
		<div class="title">
			<h1 class="title-name">
				<span class="title-name tns">our</span> products
			</h1>
			<breadcrumbs
				:links="findBreadcrumbLinks()"
			></breadcrumbs>
		</div>
		<div class="contant row">
			<div class="open-tree" @click="openTree">
				<div class="ot-arrow">
					<i class="fas fa-list-ul" style="color: white"></i>
				</div>
			</div>
			<div class="treec col-lg-4 col-12" ref="tree">
				<tree-view/>
			</div>
			<div class="main col ms-auto px-0">
				<div class="search">
					<img src="/assets/Search.png" alt />
					<input
					  @keypress.enter="search(query)"
					  v-model="query"
					  type="text"
					  placeholder="Search product name"
					/>
				</div>

				<div class="products row">
					<product-card
					  v-for="(product, index) in products"
					  :key="index"
					  :product="product"
					  class="col-lg-4 col-6"
					></product-card>

					<p v-if="products.length === 0" class="fw-bold py-2 text-secondary text-center border-top border-bottom rounded mt-5 mb-0">
						NO PRODUCTS
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ProductCard from '@/components/ProductCard.vue';
import TreeView from '@/components/TreeView.vue';
import Loader from '@/components/Loader.vue';
import sets from '@/helpers/sets';
import mixin from '@/helpers/mixins';
import { notFound } from '@/helpers/functions';
import { mapGetters } from 'vuex';

export default {
	props:['loadingCategories', 'categorySlug', 'setId', 'searchQuery'],
	components: { 
		Breadcrumbs,
		ProductCard,
		TreeView,
		Loader
	},

	mixins: [mixin],

	data() {
		return {
			loading: true,
			products: [],
			sets,
		};
	},

	watch:{
		watchRouteQuery(){
			this.fetchProducts();
		},

		searchQuery(){
			this.fetchSearchResult();
		}
	},

	methods: {
		openTree() {
			this.$refs.tree.classList.toggle('active-tree');
		},

		notFound,

		findBreadcrumbLinks(){
			if (this.categorySlug){
				const category = this.getCategoryBySlug(this.categorySlug);
				return [
					{ name: 'Home', to: { name: 'home' } },
					{ name: 'Products', to: { name: 'products' } },
					{ 
						name: sets.find(c => c.id === category.setsIds[0]).name,
						to: {
							name: 'products',
							query:{ setId: category.setsIds[0] }
						},
					},
					{ name: category.name, to: {}, active: 'true' }
				]
			}
			else if (this.setId) {
				return [
					{ name: 'Home', to: { name: 'home' } },
					{ name: 'Products', to: { name: 'products' } },
					{ name: sets.find(c => c.id === this.setId).name, to: {}, active: 'true' }
				]
			}
			else if (!this.categorySlug && !this.setId) {
				return [
					{ name: 'Home', to: { name: 'home' } },
					{ name: 'Products', to: { name: 'products' }, active: 'true' },
				]
			}
		},

		fetchProducts(){
			this.loading = true;
			if (this.categorySlug && !this.searchQuery){
				this.$store.dispatch('categories/fetchBySlug', { slug: this.categorySlug })
				.then((data) => {
					this.products = data.categoriesProduct;
				})
				.catch((e) => {
					this.notFound(e);
				})
				.finally(() => {
					this.loading = false
				})
			}
			else if (this.setId && !this.searchQuery) {
				this.$store.dispatch('products/fetchBySetId', { id: this.setId })
				.then((data) => {
					this.products = data;
				})
				.catch((e) => {
					this.notFound(e);
				})
				.finally(() => {
					this.loading = false;
				})
			}
			else if (!this.searchQuery) {
				this.$store.dispatch('products/fetchAll')
				.then((data) => {
					this.products = data;
				})
				.finally(() => {
					this.loading = false;
				})
			}
		},

		fetchSearchResult(){
			if (this.searchQuery){
				this.loading = true;
				this.$store.dispatch('products/search', { query: this.searchQuery })
				.then((data) => {
					this.products = data;
				})
				.finally(() => {
					this.loading = false;
				})
			}
		}
	},

	computed:{
		watchRouteQuery(){
			return `${this.categorySlug} , ${this.setId}`;
		},

		...mapGetters({
			getCategoryBySlug: 'categories/getCategoryBySlug',
		})
	},

	created(){
		this.fetchProducts();
		this.fetchSearchResult();
	}
};
</script>

<style scoped>
* {
	padding: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-family: Montserrat;
	color: #1b1b1b;
}

.title {
	padding: 90px 0 50px;
	color: #262626;
}
.title-name {
	font-family: MontserratBlack;
	text-transform: uppercase;
	font-size: 52px;
	line-height: 4rem;
	margin-bottom: 10px;
}
.contant {
	border-top: 2px solid var(--borders-color);
	padding-top: 10px;
}

.open-tree {
	display: none;
	height: 24px;
}
.ot-arrow {
	width: 24px;
	height: 100%;
	background: var(--text-color);
	border-radius: 4px;
	margin-right: auto;
}
.ot-arrow svg {
	margin-top: 4px;
	margin-left: 4px;
	filter: invert(100%);
}

.treec {
	min-width: 250px;
	overflow-y: auto;
}
.search {
	width: 400px;
	border-bottom: 2px solid var(--borders-color);
	margin-left: auto;
	margin-bottom: 1rem;
}
.search img {
	width: 35px;
	padding: 15px;
	padding-left: 0;
	filter: opacity(50%);
}
.search input {
	height: 40px;
	border: none;
	font-size: 13px;
	width: calc(100% - (35px + 15px));
	font-family: Montserrat;
	color: #444;
}
@media only screen and (max-width: 992px) {
	.title {
		font-size: 23px;
		padding: 35px 0;
	}
	.title-name {
		font-size: 43px;
		line-height: 3rem;
	}
	.tns {
		display: none;
	}
	.open-tree {
		display: block;
	}
	.treec {
		display: none;
		height: auto !important;
		margin-bottom: 30px;
	}
	.container .contant .active-tree {
		display: block;
		width: 100%;
	}
	.search {
		width: 100%;
	}
	.search img {
		width: 30px;
		padding: 10px;
		padding-left: 0;
	}
}
</style>
