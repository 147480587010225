import * as types from '../mutations-types'
export default {
    namespaced: true,
    
    state: {
        products: []
	},
    
    actions: {
        fetchAll({ commit }){
            return axios.get('/products')
            .then((response) => {
                commit(types.STORE_PRODUCTS, response.data.filter(c => !c.deletedAt));
                return response.data.filter(c => !c.deletedAt);
            })
        },

        fetchBySlug({ commit }, { slug }){
            return axios.get(`/products/${slug}`)
            .then((response) => {
                return response.data;
            })
        },

        fetchBySetId({ commit }, { id }){
            return axios.get(`/products/${id}/sets`)
            .then((response) => {
                return response.data;
            })
        },

        search({ commit }, { query }){
            return axios.get('/products/search', { params: { query } })
            .then((response) => {
                return response.data;
            })
        }
    },
    
    mutations: {
        [types.STORE_PRODUCTS](state, products){
            state.products = products;
        }
    },
    
    getters:{
        getProductById: state => id => state.products.find(c => c.id === id)
    }
}