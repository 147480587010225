export default {
    data(){
        return {
            query: null,
        }
    },

    methods: {
        search(search){
            this.query = null;
            this.$router.push({ name: 'products', query:{ search } });
        }
    }
}